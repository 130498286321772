import { getOrCreateProcedure } from "o365-modules";
import { alert } from "o365-vue-services";

const procGetCustomizations = getOrCreateProcedure({ procedureName: "astp_Trong_Custom_Fetch" });
const gAppID = window.location.pathname.split("/").pop();
await procGetCustomizations.execute({ App_ID: gAppID }).then(function(response:any) {
    var vIsProduction = true;
    document.head.querySelectorAll("script[src]").forEach((pScript:any) => {
        if (pScript.src.indexOf("/nt/scripts/site/o365.modules.shims.") >= 0) {
            vIsProduction = false;
        }
    });

    response.Table.forEach((pRow:any) => {
        if (pRow.Extension === "ts") { pRow.Extension += ".js"; }

        let vScript = document.createElement("script");
        vScript.setAttribute("type", vIsProduction ? "module" : "module-shim");
        vScript.setAttribute("src", `/nt/scripts/apps/${gAppID}/${pRow.App_ID}.${pRow.FileName}.${pRow.Updated}.${pRow.Extension}`);

        // vScript.setAttribute("type", "module");
        // vScript.setAttribute("src", `/nt/scripts/apps/${pRow.App_ID}/${pRow.App_ID}.${pRow.FileName}.${pRow.Updated}.${pRow.Extension}`);
        document.body.append(vScript);
    });
});

if (window["o365"] === undefined) { window["o365"] = {} };
const o365 = window["o365"];

o365.custom = {
    queues: {
        vNodes: { count: 0 },
        selectors: { count: 0 },
    },
    findElement(pOptions:any, pCallback:any) {
        if (typeof pCallback === "function") {
            let vList:string, vKey:string;

            if (typeof pOptions === "string") { //cssSelector
                vList = "selectors";
                vKey = pOptions;
            }
            else if (pOptions.attribute && pOptions.value) {
                vList = "vNodes";
                vKey = pOptions.attribute.toLowerCase() + "-" + pOptions.value.toLowerCase().replaceAll(" ", "_");

                // if (pOptions.attribute === "property") {
                //     vKey = (pOptions.nodeName ? pOptions.nodeName.toLowerCase() : "div") + "_" + vKey;
                // }
            }
            else { return; }

            if (this.queues[vList][vKey] === undefined) {
                this.queues[vList].count++;
                this.queues[vList][vKey] = pCallback;
                if (pOptions.returnNode === true) { this.queues[vList][vKey].returnNode = true; }
            }

            this.startObserver();
        }
    },
    // getElementVNode(pElement:HTMLElement, pCallback:any, pNode:any) {
    //     if (pNode === undefined) { pNode = document.querySelector("div[data-v-app]")?._vnode; }

    //     //if (pNode.el && pNode.el === pElement && pNode.scopeId && pNode.scopeId !== null) {
    //     //if (pNode.el && pNode.el === pElement && pNode.ctx?.type?.name !== undefined) {
    //     if (pNode?.el === pElement && ["Select", "PropertiesEditor", "DataLookupDropdown"].indexOf(pNode.ctx?.type?.name) >= 0) {
    //         pCallback(pNode);
    //         return;
    //     }

    //     if (pNode.children?.length > 0 && pNode.children.forEach !== undefined) {
    //         pNode.children.forEach((pChild:any) => {
    //             if (pChild.__v_isVNode === true) { this.getElementVNode(pElement, pCallback, pChild); }
    //         });
    //     }

    //     if (pNode.component?.subTree?.__v_isVNode === true) {
    //         this.getElementVNode(pElement, pCallback, pNode.component?.subTree);
    //     }
    // },
    // findElement: function(pAttribute:any, pValue:any, pCallback:any) {
    //     if (typeof pCallback === "function") {
    //         this.queues.count++;
    //         this.queues[pAttribute.toLowerCase() + pValue.toLowerCase()] = pCallback;
    //         this.startObserver();
    //     }
    // },
    startObserver() {
        if (this.startObserver.started === true) { return; }

        this.startObserver.started = true;
        var that = this;
        this.observer = new MutationObserver(function() {
            that.findVNode();
            that.findSelector();
        });

        // setTimeout(function() {
            that.findVNode();
            that.findSelector();
            // console.log(document.querySelector("div[data-v-app]"));
            // console.log(document.body);
            that.observer.observe(document.body, { childList: true, subtree: true });
        // }, 500);
    },
    stopObserver() {
        if (this.stopObserver.stopping === true) { return; }

        this.stopObserver.stopping = true;
        var that = this;
        setTimeout(function() {
            if (that.queues.vNodes.count === 0 && that.queues.selectors.count === 0 && that.startObserver.started === true) {
                that.observer.disconnect();
                delete that.startObserver.started;
                delete that.stopObserver.stopping;
            }
        }, 1500);
    },
    findSelector() {
        if (this.queues.selectors.count === 0) { return; }
        Object.keys(this.queues.selectors).forEach((pSelector:string) => {
            var vElement = document.querySelector(pSelector);
            if (vElement !== null) {
                this.queues.selectors[pSelector](vElement);
                delete this.queues.selectors[pSelector];
                this.queues.selectors.count--;
                this.stopObserver();
            }
        });
    },
    findVNode(pNode:any) {
        if (this.queues.vNodes.count === 0) { return; }
        if (pNode === undefined) { pNode = document.querySelector("div[data-v-app]")?._vnode; }

        var vAction = null,
            vElement = null;

        if (pNode?.dirs && pNode.dirs[0]?.arg?.length > 0) { //v-target
            vAction = "v-target-" + pNode.dirs[0].arg.toLowerCase();
        }
        // else if (pNode.el?.nodeName === "INPUT" && pNode.ctx?.props?.config?.name !== undefined) {
        //     vAction = pNode.el.nodeName.toLowerCase() + "_property_" + pNode.ctx.props.config.name.toLowerCase().replaceAll(" ", "_");
        // }
        else if (pNode?.el?.nodeName === "DIV"
            && pNode?.ctx?.props?.fieldName !== undefined
            && (pNode?.ctx?.props?.isProperty === true || pNode?.ctx?.type.name === "PropertiesItem")) {
            vAction = "property-" + pNode.ctx.props.fieldName.toLowerCase().replaceAll(" ", "_");
        }

        if (vAction !== null && this.queues.vNodes[vAction] !== undefined) {
            let callback = this.queues.vNodes[vAction];
            // if (vElement === null) { vElement = pNode.el; }

            if (callback.returnNode === true) { vElement = pNode; }
            else { vElement = pNode.el; }

            callback(vElement);
            delete this.queues.vNodes[vAction];
            this.queues.vNodes.count--;
            this.stopObserver();

            if (this.queues.vNodes.count === 0) { return; }
        }

        if (pNode?.children?.length > 0 && pNode.children.forEach !== undefined) {
            pNode.children.forEach((pChild:any) => {
                if (pChild.__v_isVNode === true) { this.findVNode(pChild); }
            });
        }

        if (pNode?.component?.subTree?.__v_isVNode === true) {
            this.findVNode(pNode.component?.subTree);
        }
    },
    lock(pMessage:string, pDocument:any) {
        if (pDocument === undefined) { pDocument = document; }

        if (pDocument.getElementById("cLock_Background") !== null) {
            this.unlock();
        }
        
        if (pMessage === undefined) {
            pMessage = "Please wait...";
        }
        
        let vDiv = document.createElement("div");
        vDiv.id = "cLock_Background";
        vDiv.style.position = "fixed";
        vDiv.style.top = "0";
        vDiv.style.left = "0";
        vDiv.style.width = "100%";
        vDiv.style.height = "100%";
        vDiv.style.backgroundColor = "whitesmoke";
        vDiv.style.zIndex = "1000000000000";
        vDiv.style.opacity = "50%";
        pDocument.body.appendChild(vDiv)

        vDiv = document.createElement("div");
        vDiv.id = "cLock_Message";
        vDiv.style.position = "fixed";
        vDiv.style.margin = "auto";
        vDiv.style.top = "50%";
        vDiv.style.bottom = "50%";
        vDiv.style.width = "100%";
        vDiv.style.height = "30px";
        vDiv.style.textAlign = "center";
        vDiv.style.zIndex = "1000000000001";
        vDiv.style.fontSize = "16pt";
        vDiv.style.fontWeight = "bold";
        vDiv.style.color = "darkred";
        vDiv.innerHTML = "<span class='spinner-border'></span> " + pMessage;
        pDocument.body.appendChild(vDiv)
    },
    unlock(pDocument:any) {
        if (pDocument === undefined) { pDocument = document; }

        pDocument.body.removeChild(pDocument.getElementById("cLock_Message"));
        pDocument.body.removeChild(pDocument.getElementById("cLock_Background"));
    },
    toast(pMessage:string, pType:string, pDelay:number) {
        if (pType === undefined ) { pType = "info"; }
        if (pDelay === undefined) { pDelay = 2250; }
        alert(pMessage, pType, {autohide: true, delay: pDelay});
    },
    addJavaScript(app_ID:string, fileName: string) {
        if (fileName.endsWith(".js")) { fileName = fileName.substring(0, fileName.length - 3); }

        let vScript = document.createElement("script");
        vScript.setAttribute("type", "module");
        vScript.setAttribute("src", `/nt/scripts/apps/${app_ID}/${app_ID}.${fileName}.${new Date().getTime()}.js`);
        document.body.append(vScript);
    },
    observeElement(element:any, property:string, callback:any, delay = 0) {
        let elementPrototype = Object.getPrototypeOf(element);
        if (elementPrototype.hasOwnProperty(property)) {
            let descriptor = Object.getOwnPropertyDescriptor(elementPrototype, property);
            Object.defineProperty(element, property, {
                get: function() {
                    return descriptor.get.apply(this, arguments);
                },
                set: function () {
                    let oldValue = this[property];
                    descriptor.set.apply(this, arguments);
                    let newValue = this[property];
                    if (typeof callback == "function") {
                        setTimeout(callback.bind(this, oldValue, newValue), delay);
                    }
                    return newValue;
                }
            });
        }
    }
};

o365.custom.properties = {
    names: null,
    find(propertyName:string, callback:any) {
        o365.custom.findElement({ attribute: "property", value: propertyName, returnNode: true }, function(vNode:any) {
            o365.custom.properties.names = vNode.ctx.props.row._properties;
            callback(vNode);
        });
    },
    onChanged(propertyName:string, callback:any) {
        this.find(propertyName, function(vNode:any) {
            let vInput = vNode.el.querySelector("input");

            o365.custom.observeElement(vInput, "value", function() { //oldValue, newValue
                setTimeout(function() { callback(o365.custom.properties.names[propertyName], vInput); }, 150);
            });
        });
    },
    setValue(propertyName:string, value:any, loop = 0) {
        if (loop === 0 && this.names === null) {    //using loop to avoid infinite loop
            this.find(propertyName, function() {
                o365.custom.properties.setValue(propertyName, value, loop++);
            });
            return;
        }
        if (this.names === null) { return; }

        var vRow = this.names[propertyName];
        if (vRow === undefined) { return; }

        switch(typeof value) {
            case "string":
                vRow.Value = value;
                break;

            case "number":
                vRow.IntValue = value;
                break;

            case "object":
                Object.keys(value).forEach((pKey:string) => {
                    vRow[pKey] = value[pKey];
                });
                break;
        }

        // let vInput = vNode.el.querySelector("input");
        // o365.custom.getElementVNode(vInput, function(returnNode:any) {
        //     switch (returnNode.ctx?.type?.name) {
        //         case "Select":
        //             if (typeof value === "string") { returnNode.ctx.emit("update:modelValue", value); }
        //             else if (value["Value"] !== undefined) { returnNode.ctx.emit("update:modelValue", value["Value"]); }
        //             break;

        //         case "PropertiesEditor":
        //             let vRow = returnNode.ctx.props.row;

        //             if (typeof value === "string") { vRow.Value = value; }
        //             else if (typeof value === "number") {
        //                 vRow.IntValue = value;
        //                 vRow.Value = value.toString();
        //             }
        //             else {
        //                 Object.keys(value).forEach((pKey:string) => {
        //                     vRow[pKey] = value[pKey];
        //                 });
        //             }
        //             break;

        //         case "DataLookupDropdown":
        //             setTimeout(function() {
        //                 if (typeof value === "string") { returnNode.ctx.props.value = value; }
        //                 else if (value["Value"] !== undefined) { returnNode.ctx.props.value = value["Value"]; }
        //             }, 150);
        //             break;
        //     }
        // });
    }
}

// o365.custom.findElementByAttribute = function(attribute:string, searchFor:string, cssSelector:string) {
//     if (["title"].indexOf(attribute) >= 0) {
//         cssSelector += `[${attribute}="${searchFor}"]`;
//     }

//     var vFound = null,
//         vNodeList = document.querySelectorAll(cssSelector);

//     if (vNodeList.length === 1) {
//         return vNodeList[0];
//     }
//     else if (vNodeList.length > 0) {
//         for (let i = 0; i < vNodeList.length; i++) {
//             let vNode = vNodeList[i],
//                 vObject;

//             if (vNode.__vnode) {
//                 switch (attribute) {
//                     case "v-target":
//                         vObject = vNode.__vnode.dirs;
//                         if (vObject && vObject[0].arg === searchFor) {
//                             vFound = vNode;
//                         }
//                         break;

//                     case "click":
//                         vObject = vNode.__vnode.props?.onClick;
//                         if (vObject && (vObject.name === searchFor || vObject.toString().indexOf(searchFor) >= 0)) {
//                             vFound = vNode;
//                         }
//                         break;
//                 }

//                 if (vFound !== null) { break; }
//             }
//         }
//     }

//     if (vFound === null) { console.warn("Unable to find element: " + attribute + " = " + searchFor); }
//     return vFound;
// };